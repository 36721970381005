
























































































































































































































import type { Loja, Venda } from '@/models'
import { ItemDaVenda, Produto, ResumoDaVenda, ItemDaVendaComReferencias } from '@/models'
import {
	obterTotalDoItem,
	obterSubTotalDoItem,
} from '@/shareds/venda-shareds'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { formatarMoeda } from '@/shareds/formatadores'
import { ConsultaDeProduto } from '@/usecases/venda/ConsultarProdutoUseCase'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { isVestcasa } from '@/shareds/utils'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { VendaComReferencias } from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { displayVariantes, converterProdutoParaProdutoSimples, displayNomeCompletoDoProduto } from '@/shareds/produto-shareds'
import {  EditarUmaVendaUseCase, FindVendaUseCase, FindConfiguracaoDaContaUseCase, FindLojaUseCase, FindProdutoUseCase  } from '@/usecases'
import VueQuagga from "vue-quaggajs"
import { obrigatorio } from '@/shareds/regras-de-form'
import { ItemDeRomaneio } from '@/models/Romaneio'

Vue.use(VueQuagga);


@Component({
	components:{
		CampoDeItemDeVenda,
	},
})
export default class DialogoDeVerificacaoDeProdutos extends Vue {
	@Prop({ type: Object }) loja!: Loja
	@Prop() venda!: Venda
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda

	obterTotalDoItem = obterTotalDoItem
	obterSubTotalDoItem = obterSubTotalDoItem
	displayVariantes = displayVariantes
	resumoDaVenda!: ResumoDaVenda
	produtoBipado: Produto | null = null
	formatarMoeda = formatarMoeda
	obrigatorio = obrigatorio
	vestcasa = isVestcasa
	vendas: ResumoDaVenda[] = []
	itensBipados: ItemDeRomaneio[] = []
	itensDaVenda: ItemDaVendaComReferencias[] = []
	findVendaUseCase = new FindVendaUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	editarUmaVenda = new EditarUmaVendaUseCase()

	consultaDeProduto: ConsultaDeProduto | null = null
	vendaComReferencias: VendaComReferencias | null = null
	valorLido: string | null = null
	tipoMovimentacao: string | null = null
	produtosVerificados = false
	buscando = false
	verificacaoIntermediaria: boolean | false = false
	loading = false
	carregandoVerificacaoFinal = false
	mostrarDialogoLeituraDeCodigoBarras = false
	showCamera = false
	buscandoProduto = false
	mostraConsultaDeProduto = false
	carregandoCardDeVerificacao = false
	mostra = false
	listaProdutosVerificados: string[] = []
	eansDoPedido: string[] = []
	informacoesDosProdutosDoPedido: { produtoId: string; produtosExistemNoPedido: boolean }[] = []


	get itensAhVerificar() {
		if (!this.vendaComReferencias) return []
		return this.vendaComReferencias.itens.filter(item => !item.motivoCancelamento)
	}

	get nomeDaTabelaDePreco() {
		if (!this.consultaDeProduto) return []
		return this.consultaDeProduto.precos.flatMap( tabela => tabela)
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}
	
	get buscaUnica() {
		return this.vestcasa
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	get verificacaoDeProdutosSalva(): boolean {
		if(this.vendaComReferencias){
			return this.vendaComReferencias.produtosVerificados
		}
		return false
	}

	mostrar(){
		this.mostra = true
	}


	atalhoDeSelecaoDeProduto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 's') {
			this.focarBusca()
		}
	}

	focarBusca() {
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}

	readerSize = {
		width: 860,
		height: 480,
	}
	
	verificaSemelhancaDeProdutos(item: ItemDeRomaneio){
		return this.informacoesDosProdutosDoPedido.some(itemDaLista => 
			itemDaLista.produtosExistemNoPedido === true && itemDaLista.produtoId === item.produto.id)
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido && this.lojaDaVenda) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.lojaDaVenda.id,
				)

				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: 0,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco || 0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
				}

				if(!this.eansDoPedido.includes(this.valorLido)) {
					AlertModule.setError("Produto não encontrado no pedido!")
				} 

				if(this.eansDoPedido.includes(this.valorLido)) {
					AlertModule.setSuccess("Produto encontrado no pedido!")
				}

				this.incluirItensNaTabela(item)
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

	async incluirItensNaTabela(item: ItemDaVenda) {
		this.loading = true;

		if (!this.venda) {
			AlertModule.setError('Ocorreu um erro ao verificar produto, contate o suporte')
			this.loading = false;
			return;
		}

		try {
			const itemBipado: ItemDeRomaneio = {
				id: '',
				produto: {
					id: item.produto.id,
					nome: displayNomeCompletoDoProduto(item.produto),
					tipo: item.produto.tipo,
					preco: item.produto.preco,
					eans: item.produto.eans,
					sku: item.produto.sku || '',
					identificadores: item.produto.identificadores,
					nomeCompleto: item.produto.nomeCompleto,
					produtoDesativado: item.produto.produtoDesativado,
					descontoVarejo: item.produto.descontoVarejo,
					pesoBruto: item.produto.pesoBruto,
					pesoLiquido: item.produto.pesoLiquido,
					isValePresente: item.produto.isValePresente,
					urlImagens: item.produto.urlImagens,
					skuPai: item.produto.skuPai,
				},
				quantidade: 1,
				dataHoraCriacao: new Date(),
			};

			const indiceDoItemBipado = this.itensBipados.findIndex(item => item.produto.sku === itemBipado.produto.sku);


			if (indiceDoItemBipado === -1) {
				this.itensBipados.push(itemBipado)
			} else {
				this.itensBipados.splice(indiceDoItemBipado, 1)
				this.itensBipados.push(itemBipado)
			}

			this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.venda.id);
			const produtoLidoPeloCodigo = itemBipado.produto.id
			
			for (const itens of this.itensAhVerificar) {
				this.eansDoPedido.push(itens.produto.id)

				if(produtoLidoPeloCodigo === itens.produto.id && !this.listaProdutosVerificados.includes(itens.produto.id)){
					this.listaProdutosVerificados.push(itens.produto.id)
					this.informacoesDosProdutosDoPedido.push({produtoId: itemBipado.produto.id, produtosExistemNoPedido:true})
			
				} 
				if(produtoLidoPeloCodigo !== itens.produto.id && !this.listaProdutosVerificados.includes(itens.produto.id)) {
					this.informacoesDosProdutosDoPedido.push({produtoId: itemBipado.produto.id, produtosExistemNoPedido: false})
				}

				const todosProdutosVerificadosPresentes = this.itensAhVerificar.every(item => 
					this.listaProdutosVerificados.some(produtoId => produtoId === item.produto.id))

	
				if (todosProdutosVerificadosPresentes) {
					this.showCamera = false
					this.mostrarDialogoLeituraDeCodigoBarras = false
					this.produtosVerificados = true
					this.verificacaoIntermediaria = true
				}
			}

			if(this.produtosVerificados === true) {
				this.carregandoVerificacaoFinal = true
				const vendaAtualizada = await new EditarUmaVendaUseCase().executar(this.venda.id, {
					...this.venda, 
					produtosVerificados: true,
				});
				this.vendaComReferencias.produtosVerificados = vendaAtualizada.produtosVerificados

				this.listaProdutosVerificados.splice(0, this.listaProdutosVerificados.length);
				this.informacoesDosProdutosDoPedido.splice(0, this.informacoesDosProdutosDoPedido.length)
				
				this.carregandoVerificacaoFinal = false
			}
		} catch (error: any) {
			AlertModule.setError(error);
		} finally {
			this.loading = false;
			
		}
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	fecharDialogo(){
		this.mostra = false
		this.mostraConsultaDeProduto = false
	}

	async buscaVenda() {
		if (!this.venda) return
		try {

			this.carregandoCardDeVerificacao = true
			this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.venda.id)
			
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.carregandoCardDeVerificacao = false
		}
	}

	
	@Watch('mostra')
	onChangeMostra(){
		this.buscaVenda()
	}
}

